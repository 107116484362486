import React from 'react';
import {useEffect, useState} from "react";
import axiosClient from "../axios-client.js";
import _ from 'lodash';
import moment from '../js/moment-config.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import RideSeats from '../components/RideSeats';
import TrainSeats from '../components/TrainSeats';

const DetallesClase = () => {
  const [classDetails, setClass] = useState({
    class: {
      coach_name:'',
    },
    seats: []
  });
  const [seatDetails, setSeat] = useState(0);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRes, setLoadingRes] = useState(false);
  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    getClassDetails();
  }, [])

  const getClassDetails = () => {
    setLoading(true)
    axiosClient.get(`/classes/${id}`)
      .then(({ data }) => {
        setClass(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
  }

  const handleClose = () => {
    setSeat(0);
    setShow(false);
  }

  const handleShow = (seat) => {
    setSeat(seat);
    setShow(true);
  }

  const reservarLugar = (seat) => {
    setLoadingRes(true)
    const user = JSON.parse(localStorage.getItem('USER_DATA'));
    if (!user) {
      swal({
        title: "Error",
        text: "Ocupa crear un usuario y contar con un paquete de clases para poder reservar",
        icon: "error",
      }).then(() => {
        navigate('/auth/signup')
      });
    }

    const reservation = {
      class_id: classDetails.class.id,
      user_id: user.id,
      bike_number: seat,
      status: "Reservada"
    }

    axiosClient.post(`/reservations`, reservation)
      .then(({ data }) => {
        handleClose();
        swal({
          title: "Reservacion exitosa",
          text: "Gracias",
          icon: "success",
        });
        const updatedSeats = classDetails.seats.map((s) => {
          if (s.seat_number === seat) {
            return {
              ...s,
              status: 1
            };
          }
          return s;
        });
        setClass((prevClass) => ({
          ...prevClass,
          seats: updatedSeats
        }));
        setLoadingRes(false)
        navigate('/mis-clases')
      })
      .catch((error) => {
        const err = JSON.parse(error.request.response);
        handleClose();
        setLoadingRes(false)
        swal({
          title: "Error",
          text: err.message,
          icon: "error",
        }).then(() => {
          navigate('/reservar-clases')
        });
      });
  }

  return (
    <div className='ps-4 pe-4'>
      <div className='mt-5 mb-4'>
        <p className='calendario-titulo'>Reserva en línea</p>
      </div>

      {loading && !classDetails.class ? (
        <div className='loading-container center'>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className='row'>
          <div className='col-lg-3 detalle-clase'>
            <p><span className='bold'>Hora de la clase:</span> {classDetails.class.class_time}</p>
            <p><span className='bold'>Fecha:</span> {classDetails.class.class_date}</p>
            <p><span className='bold'>Duración: </span>{classDetails.class.duration} Minutos</p>
            <p className='texto-bola'>
              <span className='bold'>Lugares Disponibles:</span>
              <span className={`bola-seat info ${classDetails.class.type_class=='Train' ? 'bola-roja' : ''}`}>
              </span>
            </p>
            <p className='texto-bola'>
              <span className='bold '>Lugares Ocupados:</span>
              <span className='bola-seat ocupado info' >
              </span>
            </p>
            {classDetails.class.type_class === 'Ride' && (
              <p className='bold'>** Los lugares 1 y 2 estaran del lado derecho del coach en la clase</p>
            )}
            
          </div>

          <div className='col-lg-9'>
            {classDetails.class.type_class === 'Train' ? (
              <TrainSeats classDetails={classDetails} handleShow={handleShow} />
            ) : (
              <RideSeats classDetails={classDetails} handleShow={handleShow} />
            )}
          </div>
        </div>
      )}

      <Modal className="modal fade" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación de lugar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            ¿Esta seguro de reservar el lugar <strong>{seatDetails}</strong> para el día: <strong>{classDetails.class.class_date}</strong> - <strong>{classDetails.class.class_time}</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => reservarLugar(seatDetails)} disabled={loadingRes}>
            Reservar
          </Button>
          <Button variant="secondary" onClick={handleClose} disabled={loadingRes}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DetallesClase;