import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle';
import {useEffect, useState} from "react";
import axiosClient from "../axios-client";
import Button from 'react-bootstrap/Button';
import { useStateContext } from "../contexts/ContentProvider";
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

const MisClases = () => {
  const [nextClasses, setIncoming] = useState([]);
  const [previousClasses, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getClasses();
  }, []);

  const getClasses = () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('USER_DATA'));
    const incomingPromise = axiosClient.get('/reservations/user/' + user.id)
      .then(({ data }) => {
        setIncoming(data);
      })
      .catch((e) => {
        console.log(e)
      });
  
    const historyPromise = axiosClient.get('/reservations/user/history/' + user.id)
      .then(({ data }) => {
        setHistory(data.data);
      })
      .catch((e) => {
        console.log(e)
      });
  
    Promise.all([incomingPromise, historyPromise])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const cancelarRes = (res) => {
    swal("¿Esta seguro de cancelar su reservacion el: "+res.class_date+" a las: "+res.class_time+"?", 
    {
      dangerMode: true,
      buttons: true,
    }).then(result => {
      if (result) {
        setLoadingCancel(true);
        axiosClient
          .put('/reservations/'+res.id)
          .then(({ data }) => {
            if(data.confirmation) {
              swal({
                title: "Confirmacion",
                text: data.confirmation,
                icon: "warning",
                buttons: ["Cancelar", "Confirmar"],
              }).then((result) => {
                if (result) {
                  confirmacionCancel(res.id);
                } else {
                  setLoadingCancel(false);
                }
              });
            } else {
              swal({
                title: "Cancelación Exitosa",
                text: "El crédito de su clase se volvio a abonar al paquete de clases correspondiente",
                icon: "success",
              }).then(() => {
                window.location.reload();
              });
              setLoadingCancel(false);
            }
          })
          .catch((error) => {
            const err = JSON.parse(error.request.response);
            swal({
              title: "Error",
              text: err.message,
              icon: "error",
            })
            setLoading(false);
          });
      }
    });
  }

  const confirmacionCancel = (id) => {
    axiosClient
      .put('reservations/'+id+'/cancel?confirmCancellation=true')
      .then(({data}) => {
        swal({
          title: "Cancelación Exitosa",
          text: "Se libero el asiento de su clase.",
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
        setLoadingCancel(false);
      }).catch((error) => {
        console.log(error)
        setLoadingCancel(false);
      })
  }
  
  return (
    <div className='container '>
      <div className='datos-container'>
        <p className='semi-bold'>Próximas Clases</p>
        <hr></hr>
          {loading ? (
            <div className='loading-container center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
          ) : (
            !nextClasses ? (
              <p className='bold ms-4'>No tienes clases pronto</p>
            ) : (
              <div className='table-responsive'>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Clase</th>
                      <th>Hora</th>
                      <th>Coach</th>
                      <th>Lugar</th>
                      <th>Estatus</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {nextClasses.map((data) => (
                      <tr key={data.id}>
                        <td>{data.class_date}</td>
                        <td>{data.type_class}</td>
                        <td>{data.class_time}</td>
                        <td>{data.coach}</td>
                        <td>{data.bike_number}</td>
                        <td>{data.status}</td>
                        <td>
                          <Button variant="secondary" disabled={loadingCancel}
                            onClick={() => cancelarRes(data)}>
                            Cancelar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          )}
      </div>


      <div className='datos-container'>
        <p className='semi-bold'>Historial de Clases</p>
        <hr></hr>
        {loading ? (
            <div className='loading-container center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
          ) : (
            !previousClasses ? (
              <p>No tiene historial de clases</p>
            ) : (
              <div className='table-responsive'>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Clase</th>
                      <th>Hora</th>
                      <th>Coach</th>
                      <th>Lugar</th>
                      <th>Estatus</th>
                    </tr>
                  </thead>
                  <tbody>
                    {previousClasses.map((data) => (
                      <tr key={data.id}>
                        <td>{data.class_date}</td>
                        <td>{data.type_class}</td>
                        <td>{data.class_time}</td>
                        <td>{data.coach}</td>
                        <td>{data.bike_number}</td>
                        <td>{data.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default MisClases;