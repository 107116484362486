import React from 'react';
import {useEffect, useState} from "react";
import axiosClient from "../axios-client.js";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';

const AdminPanel = () => {
  const [reservations, setReservations] = useState([]);
  const [packages, setPackages] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [loadingToken, setLoadingToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPack, setLoadingPack] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [paginationPack, setPaginationPack] = useState(null);
  const [paginationToken, setPaginationToken] = useState(null);
  const [loadVal, setLoadVal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkUser();
  }, [])

  const checkUser = () => {
    axiosClient
      .get('/perfil')
      .then(({ data }) => {
        setLoading(false);
        if (data.role !== "staff") {
          navigate('/')
        }
        getReservations();
        getPackages();
        getTokens();
      })
      .catch(() => {
        setLoading(false);
        navigate('/')
      });
  };

  const getReservations = (page = 1) => {
    setLoading(true);
    axiosClient
      .get('/reservations', { params: { page } })
      .then(({ data }) => {
        setLoading(false);
        setReservations(data.data);
        setPagination(data.pagination);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    getReservations(page);
  };

  const handlePageChangePack = (page) => {
    getPackages(page);
  };

  const getPackages = () => {
    setLoadingPack(true)
    axiosClient.get('/package-history')
      .then(({ data }) => {
        setLoadingPack(false)
        setPackages(data.data)
        setPaginationPack(data.pagination);
      })
      .catch(() => {
        setLoadingPack(false)
      })
  }

  const getTokens = () => {
    setLoadingToken(true)
    axiosClient.get('/package-tokens')
      .then(({ data }) => {
        setLoadingToken(false)
        setTokens(data.data)
        setPaginationToken(data.pagination);
      })
      .catch(() => {
        setLoadingToken(false)
      })
  }

  const valToken = (data) => {
    swal({
      title: "Validar Token",
      text: "¿Desea validar el token: "+data.token+" para el usuario: "+data.user,
      icon: "info",
      buttons: ["Cancelar", "Confirmar"],
    }).then((result) => {
      if (result) {
        updateToken(data);
      }
    });
  }
  
  const updateToken = (data) => {
    axiosClient.put('/package-tokens/'+data.id, data)
      .then(({ data }) => {
        swal({
          title: "Token validado",
          text: "El token se agrego a un paquete.",
          icon: "success",
        }).then((result) => {
          getTokens();
        });
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className='container'>
      {loading ? (
        <div className='loading-container center'>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <div className='datos-container'>
            <p className='semi-bold'>Reservaciones</p>
            <hr></hr>
            {!!!reservations ? (
              <p>No hay reservaciones de momento</p>
            ) : (
              <div className='table-responsive'>
                {pagination && (
                  <div className='pagination'>
                    {pagination.prev_page_url && (
                      <button onClick={() => handlePageChange(pagination.current_page - 1)}>Anterior</button>
                    )}

                    <span className='me-3 ms-3'>{pagination.current_page}</span>

                    {pagination.next_page_url && (
                      <button onClick={() => handlePageChange(pagination.current_page + 1)}>Siguiente</button>
                    )}
                  </div>
                )}
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Usuario</th>
                      <th>Paquete</th>
                      <th>Lugar</th>
                      <th>Clase</th>
                      <th>Hora</th>
                      <th>Estatus</th>   
                    </tr>
                  </thead>
                  <tbody>
                    {reservations.map((data) => (
                      <tr key={data.id}>
                        <td>{data.id}</td>
                        <td>{data.user}</td>
                        <td>{data.package}</td>
                        <td>{data.bike_number}</td>
                        <td>{data.class_date}</td>
                        <td>{data.class_time}</td>
                        <td>{data.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {pagination && (
                  <div className='pagination'>
                    {pagination.prev_page_url && (
                      <button onClick={() => handlePageChange(pagination.current_page - 1)}>Anterior</button>
                    )}

                    <span className='me-3 ms-3'>{pagination.current_page}</span>

                    {pagination.next_page_url && (
                      <button onClick={() => handlePageChange(pagination.current_page + 1)}>Siguiente</button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

        </div>
      )}
      
      {loadingPack ? (
        <div className='loading-container center'>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
          <div className='datos-container'>
            <p className='semi-bold'>Paquetes Comprados</p>
            <hr></hr>
            {!!!packages ? (
              <p>No hay clases </p>
            ) : (
              <div className='table-responsive'>
                {paginationPack && (
                  <div className='pagination'>
                    {paginationPack.prev_page_url && (
                      <button onClick={() => handlePageChangePack(paginationPack.current_page - 1)}>Anterior</button>
                    )}

                    <span className='me-3 ms-3'>{paginationPack.current_page}</span>

                    {paginationPack.next_page_url && (
                      <button onClick={() => handlePageChangePack(paginationPack.current_page + 1)}>Siguiente</button>
                    )}
                  </div>
                )}
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Usuario</th>
                      <th>Fecha Compra</th>
                      <th>Fecha Expiración</th>
                      <th>Paquete</th>
                      <th>Clases Compradas</th>
                      <th>Clases Usadas</th>
                      <th>Clases Disponibles</th> 
                      <th>Estatus</th>   
                    </tr>
                  </thead>
                  <tbody>
                    {packages.map((data) => (
                      <tr key={data.id}>
                        <td>{data.id}</td>
                        <td>{data.user}</td>
                        <td>{data.transaction_date}</td>
                        <td>{data.expiration_date}</td>
                        <td>{data.package}</td>
                        <td>{data.total_classes}</td>
                        <td>{data.used_classes}</td>
                        <td>{data.available_classes}</td>
                        <td>{data.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {paginationPack && (
                  <div className='pagination'>
                    {paginationPack.prev_page_url && (
                      <button onClick={() => handlePageChangePack(paginationPack.current_page - 1)}>Anterior</button>
                    )}

                    <span className='me-3 ms-3'>{paginationPack.current_page}</span>

                    {paginationPack.next_page_url && (
                      <button onClick={() => handlePageChangePack(paginationPack.current_page + 1)}>Siguiente</button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
      )}

    </div>
  );
};
export default AdminPanel;