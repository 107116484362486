import React from 'react';

const RideSeats = ({ classDetails, handleShow }) => {
  // Divide los asientos en filas de 6 y 7 asientos
  const seatsPerRow = [3, 8, 7];
  let currentIndex = 0;
  const seatRows = seatsPerRow.map((numSeats) => {
    const rowSeats = classDetails.seats.slice(currentIndex, currentIndex + numSeats);
    currentIndex += numSeats;
    return rowSeats;
  });
  

  return (
    <>
      <div className={`bola-coach ${classDetails.class.type_class === 'Train' ? 'bola-roja' : ''}`}>
        <span className='name-coach'>{classDetails.class.coach_name ? classDetails.class.coach_name : "Coach"}</span>
      </div>
      <div className='seats-container'>
        {seatRows.map((row, rowIndex) => (
          <div className='row' key={`row-${rowIndex}`}>
            {row.map((s) => (
              <div className='bola-fila' key={s.id}>
                <div 
                  onClick={() => s.status !== 1 && handleShow(s.seat_number)}
                  className={`bola-seat 
                    ${s.seat_number > 11 ? 'segunda-fila' : ''}
                    ${classDetails.class.type_class === 'Train' ? 'bola-roja' : ''}
                    ${s.status === 1 ? 'ocupado' : ''}`
                  }
                >
                  <span>{s.seat_number}</span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default RideSeats;
